
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import MyWebsiteRegistrationReadDto
  from 'tradingmate-modules/src/models/api/my-websites/registration/MyWebsiteRegistrationReadDto'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import {
  MyWebsiteRegistrationStatus
} from 'tradingmate-modules/src/models/api/my-websites/registration/enums/MyWebsiteRegistrationStatus'
import Badge from '@/components/status/Badge.vue'
import {
  MyWebsiteRegistrationStep
} from 'tradingmate-modules/src/models/api/my-websites/registration/enums/MyWebsiteRegistrationStep'

@Component({
  computed: {
    MyWebsiteRegistrationStep () {
      return MyWebsiteRegistrationStep
    },
    MyWebsiteRegistrationStatus () {
      return MyWebsiteRegistrationStatus
    }
  },
  components: {
    Badge,
    Table,
    TableHeader,
    TableRow,
    TableData
  }
})

export default class WebsiteDatabase extends Vue {
  private websiteRegistration: MyWebsiteRegistrationReadDto[] = []
  private loading = false
  // private websiteManuallyRestartingIds: string[] = []

  mounted (): void {
    this.getMyWebsiteRegistrations()
  }

  getMyWebsiteRegistrations (): void {
    this.loading = true
    Services.API.MyWebsiteRegistration.QueryStartedWebsiteRegistrations()
      .then((x) => {
        this.websiteRegistration = x
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }

  onManualRestart (id: string): void {
    const reg = this.websiteRegistration.find(x => x.MyWebsiteRegistrationId === id)
    if (!reg) return
    reg.Restarting = true
    Services.API.MyWebsiteRegistration.ManualRestart(id)
      .then((response) => {
        const website = this.websiteRegistration.find(x => x.MyWebsiteRegistrationId === response.MyWebsiteRegistrationId)
        response.Restarting = false
        Object.assign(website, response)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        reg.Restarting = false
      })
  }
}

