export enum MyWebsiteRegistrationStep {
    None = 0,
    ValidatingInfo = 1,
    RegisteringDomain = 2,
    SetDomainDetails = 3,
    CreateCloudFrontDistribution = 4,
    CreateCloudFrontDnsRecords = 5,
    CreateSslCert = 6,
    ValidateSslCert = 7,
    ConfigureDist = 8,
    Complete = 8
}